import React from 'react';
import { AddressOutput, Handler } from 'wix-ui-core/dist/src/clients/GoogleMaps/types';
import { getAtlasClientConstructor, getAddressFromGoogleResult } from '../../../../core/AtlasClient/AtlasClient';
import { EMPTY_ADDRESS } from '../../../../core/constants';
import { Converter } from 'wix-ui-core/dist/src/components/address-input/AddressInput';
import { AddressInput as CoreAddressInput } from 'wix-ui-core/address-input';
import { classes } from './AddressInput.st.css';
import { Tooltip } from 'wix-ui-tpa/Tooltip';
import { ReactComponent as ErrorIcon } from '../../../../assets/icons/error.svg';
import dataHooks from '../../data-hooks';
import { TooltipSkin } from 'wix-ui-tpa/dist/src/components/Tooltip/TooltipEnums';
import { ClearIcon } from 'wix-ui-tpa/dist/src/components/TextField/ClearIcon';
import { IconButton, Skins } from 'wix-ui-tpa/dist/src/components/IconButton/IconButton';
import { Address } from '@wix/restaurants-client-logic';
import { ReactComponent as CheckSuccess } from 'wix-ui-tpa/dist/statics/assets/icons/CheckSuccess.svg';
import classNames from 'classnames';

export interface AddressInputProps {
  value: string;
  onSelect: (address: Address) => void;
  'data-hook'?: string;
  toolTipText?: string;
  onChange: (value: string) => void;
  placeholder?: string;
  signedInstance: string;
  isMobile: boolean;
  onBlur?: (address?: Address) => void;
  isSuccess?: boolean;
}

class AddressInput extends React.PureComponent<AddressInputProps> {
  static displayName = 'AddressInput';
  ref = React.createRef<CoreAddressInput>();
  selectedAddress?: Address = undefined;

  getSuffix = () => {
    return (
      <>
        {this.props.value && (
          <div className={classes.clearButtonWrapper}>
            <IconButton
              className={classes.clearButton}
              data-hook={dataHooks.addressInformationInputClear}
              aria-label="Clear"
              skin={Skins.Line}
              onClick={() => {
                this.props.onChange('');
                this.props.onSelect(EMPTY_ADDRESS);

                if (this.ref.current) {
                  this.ref.current.clear();
                }
              }}
              icon={<ClearIcon />}
            />
            {!this.props.toolTipText && !this.props.isSuccess && <div className={classes.clearButtonGap} />}
          </div>
        )}
        {this.props.toolTipText && (
          <Tooltip
            data-hook={dataHooks.addressInformationInputErrorText}
            content={this.props.toolTipText}
            placement="top-end"
            appendTo={'window'}
            zIndex={1020}
            skin={TooltipSkin.Error}
          >
            <div
              data-hook={dataHooks.addressInformationInputErrorIcon}
              style={{ pointerEvents: 'none' }}
              data-error-message={this.props.toolTipText}
              className={classes.iconWrapper}
            >
              <ErrorIcon />
            </div>
          </Tooltip>
        )}
        {this.props.isSuccess && (
          <div data-hook={dataHooks.addressInformationInputSuccessIcon} className={classes.iconWrapper}>
            <CheckSuccess />
          </div>
        )}
      </>
    );
  };

  preOnSelect(raw: AddressOutput) {
    if (this.ref.current?.props.value) {
      this.ref.current.close();
      this.ref.current.blur();

      const { onSelect } = this.props;
      const address = getAddressFromGoogleResult(raw);
      onSelect(address);
      this.selectedAddress = address;
    }
  }

  render() {
    const { value, signedInstance, isMobile, onBlur = () => {} } = this.props;

    const className = classNames({
      [classes.root]: true,
      [classes.error]: this.props.toolTipText,
      [classes.success]: this.props.isSuccess,
    });
    const AtlasClient = getAtlasClientConstructor(signedInstance);

    return (
      <CoreAddressInput
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setImmediate(() => {
              if (this.ref.current) {
                this.ref.current.blur();
              }
            });
          }
        }}
        id="address-input"
        className={className}
        data-hook={this.props['data-hook'] || 'address-input'}
        handler={Handler.places}
        clientId=""
        onChange={(e) => {
          this.selectedAddress = undefined;
          this.props.onChange(e.target.value);
        }}
        emptyStateMessage={'No Results'}
        onSelect={(raw) => this.preOnSelect(raw)}
        Client={AtlasClient as any}
        converterType={Converter.simple}
        value={value}
        fixed
        placeholder={this.props.placeholder}
        suffix={this.getSuffix()}
        ref={this.ref}
        throttleInterval={400}
        forceSelect
        optionStyle={isMobile ? { fontSize: '14px' } : {}}
        onBlur={() => onBlur(this.selectedAddress)}
      />
    );
  }
}

export default AddressInput;
