import { ConditionReason, DispatchType, DisplayableDiscount } from '@wix/restaurants-client-logic';
import { TranslationFunction } from 'i18next';

export function getErrorString(
  error: ConditionReason,
  dispatchType: DispatchType,
  t: TranslationFunction,
  isMobile: boolean,
  isDishDeleted: boolean,
) {
  if (isDishDeleted) {
    return t('cart_item_error_item_removed');
  }

  switch (error?.type) {
    case 'order_delivery_type':
      return dispatchType === 'delivery'
        ? t('checkout_confirm_modal_delivery_error')
        : t('checkout_confirm_modal_pickup_error');
    case 'order_delivery_time':
      return error.reason === 'soldout' ? t('cart_item_error_out_of_stock') : t('cart_item_error_not_available_future');
    case 'order_platform':
      return t(`cart_item_error_unavailable_${isMobile ? 'mobile' : 'desktop'}`);
    default:
      return error?.type;
  }
}

export function getDisplayableDiscountCondition(dd: DisplayableDiscount, t: TranslationFunction): string | undefined {
  if (dd.minPrice) {
    return t('cart_promo_min_order__price_label', { price: dd.displayableMinPrice });
  } else {
    return dd.errors[0]?.type;
  }
}
