import React, { useEffect } from 'react';
import { scroller } from 'react-scroll';
import { useBi } from 'yoshi-flow-editor-runtime';
import { ALIGNMENT, SKIN, VARIANT } from 'wix-ui-tpa/Tabs';
import { TabItem } from 'wix-ui-tpa/dist/src/components/Tabs/Tab';
import { calculateTopValue } from '../Scroller/scrollingUtils';
import Tabs from '../Tabs';
import styles from './SectionTabs.scss';

export interface SectionTabsProps {
  sections: TabItem[];
  dataHook?: string;
  className?: string;
  isRTL?: boolean;
  isMobile?: boolean;
  selectedSectionId?: string;
}

const getScrollOptions = (isMobile?: boolean) => {
  return {
    smooth: 'easeInOutCubic',
    duration: 200,
    offset: isMobile ? -(20 + calculateTopValue(0)) : -(80 + calculateTopValue(0)),
  };
};

const SectionTabs: React.FC<SectionTabsProps> = ({
  sections,
  isMobile,
  isRTL,
  className,
  dataHook,
  selectedSectionId: initialSelectedSectionId,
}) => {
  const [selectedSectionId, setSectionId] = React.useState(initialSelectedSectionId || sections[0]?.id);
  const biLogger = useBi();

  useEffect(() => {
    if (initialSelectedSectionId) {
      setSectionId(initialSelectedSectionId);
    }
  }, [initialSelectedSectionId]);

  return (
    <div className={`${styles.wrapper} ${className}`}>
      <Tabs
        data-hook={dataHook}
        aria-label={'Sub menus'}
        activeTabIndex={sections.findIndex((e) => e.id === selectedSectionId)}
        alignment={isRTL ? ALIGNMENT.right : ALIGNMENT.left}
        items={sections}
        onTabClick={(selectedTabIndex) => {
          const sectionId = sections[selectedTabIndex].id;
          const sectionName = sections[selectedTabIndex].title;
          biLogger.navigateToSection({ sectionId, sectionName });
          if (sectionId) {
            scroller.scrollTo(sectionId, getScrollOptions(isMobile));
            setSectionId(sectionId);
          }
        }}
        skin={SKIN.fullUnderline}
        variant={VARIANT.fit}
      />
    </div>
  );
};

export default SectionTabs;
