import OrderFailureModal from './OrderFailureModal';
import { AppState } from '../../../../state/createStore';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { setCheckoutStep, setErrorType } from '../../../../state/checkout/checkout.actions';
import { translate } from 'yoshi-flow-editor-runtime';

function mapStateToProps(state: AppState, ...ownProps: any) {
  return {
    errorType: state.checkout.errorType,
    errorCode: state.checkout.errorCode,
    ...ownProps,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      resetErrors: () => setErrorType({ errorType: undefined, errorCode: undefined }),
      setCheckoutStep,
    },
    dispatch,
  );
}
export default translate()(connect(mapStateToProps, mapDispatchToProps)(OrderFailureModal));
