import React, { useEffect } from 'react';
import moment from 'moment-timezone';
import Dropdown from '../Dropdown';
import Text from '../../core-components/Text/Text';
import dataHooks from '../../data-hooks';
import { TimingOption } from '../DispatchSettings/DispatchSettings';
import styles from './DispatchTimeSelector.scss';
import { TranslationFunction } from 'i18next';
import {
  DispatchType,
  Restaurant,
  DispatchInfo,
  BusinessNotifications,
  getFinalAvailability,
} from '@wix/restaurants-client-logic';
import { getDateDropdownOptions, getTimeDropdownOptions, getAsapText } from './DispatchTimeSelector.helper';
import { BusinessNotificationDetails } from '../../../../state/selectors/businessNotificationSelector';

interface DispatchTimeProps {
  restaurant: Restaurant;
  dispatchType: DispatchType;
  timingOption?: TimingOption;
  dispatchTime?: number;
  onChange: (changes: { timingOption: TimingOption; selectedDateTime?: number }) => void;
  t: TranslationFunction;
  isMobile?: boolean;
  error?: string;
  idealDeliveryArea?: DispatchInfo;
  businessNotification?: BusinessNotificationDetails;
}

export const DispatchTime = ({
  timingOption = 'asap',
  dispatchTime,
  restaurant,
  dispatchType,
  onChange,
  t,
  isMobile,
  error,
  idealDeliveryArea,
  businessNotification,
}: DispatchTimeProps) => {
  const { timezone, deliveryInfos } = restaurant;
  const initialTime = moment(dispatchTime).tz(timezone);
  const asapText = getAsapText({
    timezone,
    deliveryInfos,
    dispatchType,
    t,
    idealDeliveryArea,
  });
  const numberOfDays = restaurant.orders.future.delayMins?.max / 1440;
  const finalAvailability = getFinalAvailability(restaurant, dispatchType, idealDeliveryArea);
  const dateOptions = getDateDropdownOptions(
    finalAvailability,
    timezone,
    numberOfDays,
    restaurant.orders.future.delayMins,
    t,
  );
  const selectedDate = dispatchTime ? initialTime.startOf('day').valueOf() : Number(dateOptions[0]?.id);

  const timeOptions = getTimeDropdownOptions(
    finalAvailability,
    timezone,
    selectedDate,
    restaurant.orders.future.delayMins,
  );
  const earliestTimeOptions = dateOptions[0]?.id
    ? getTimeDropdownOptions(
        finalAvailability,
        timezone,
        parseInt(dateOptions[0].id, 10),
        restaurant.orders.future.delayMins,
      )
    : [];

  const isOnlyAsap = restaurant.orders.future.disabled && !restaurant.orders.asap.disabled;
  const isOnlyFutureOrders =
    dispatchType === 'delivery'
      ? businessNotification?.notification === BusinessNotifications.OnlyFutureOrders
      : !restaurant.orders.future.disabled &&
        earliestTimeOptions[0]?.id &&
        moment(parseInt(earliestTimeOptions[0].id, 10)).tz(timezone) >
          moment()
            .tz(timezone)
            .add(restaurant.orders.future.delayMins.min + 15, 'minutes');

  if (isOnlyFutureOrders) {
    timingOption = 'later';
  }

  const shouldChooseDateAndTime =
    !restaurant.orders.future.disabled &&
    (timingOption === 'later' || restaurant.orders.asap.disabled || isOnlyFutureOrders);

  const timingOptionError = timingOption === 'asap' ? error : undefined;
  const dateError = timingOption !== 'asap' ? error : undefined;
  const timeError = timingOption !== 'asap' ? error : undefined;

  useEffect(() => {
    if (!timeOptions.length) {
      return;
    }

    const found = Boolean(timeOptions.find((option) => option.id === String(dispatchTime)));

    if (!found && timingOption === 'later') {
      onChange({
        timingOption,
        selectedDateTime: Number(timeOptions[0].id),
      });
    }
  }, [dispatchType, dispatchTime, timingOption, onChange, timeOptions]);

  return (
    <div>
      <Text typography="p2-s" className={styles.label}>
        {dispatchType === 'delivery' ? t('checkout_main_delivery_time') : t('checkout_main_pickup_time')}
      </Text>
      {isOnlyAsap && (
        <Text typography="p2-m" className={styles.label} data-hook={dataHooks.asapTextWithoutDropdown}>
          {asapText}
        </Text>
      )}
      {!isOnlyFutureOrders && !restaurant.orders.future.disabled && !restaurant.orders.asap.disabled && (
        <Dropdown
          key={asapText}
          className={styles.timing}
          upgrade
          data-hook={dataHooks.dispatchSummaryTimingOption}
          options={[
            { id: 'asap', value: asapText, isSelectable: true },
            { id: 'later', value: t('checkout_main_specific_time'), isSelectable: true },
          ]}
          onChange={({ id }) => {
            const newTimingOption: TimingOption = id as TimingOption;
            onChange({
              timingOption: newTimingOption,
              selectedDateTime: newTimingOption === 'later' ? dispatchTime || Number(timeOptions[0].id) : undefined,
            });
          }}
          initialSelectedId={timingOption}
          error={Boolean(timingOptionError)}
          errorMessage={timingOptionError}
          mobileNativeSelect
        />
      )}
      {shouldChooseDateAndTime && (
        <React.Fragment>
          <div className={`${styles.dateTimeWrapper} ${isMobile && styles.mobile}`}>
            <div id="date-selector" className={styles.date}>
              <Dropdown
                upgrade
                data-hook={dataHooks.dispatchSummaryTimingDate}
                options={dateOptions}
                onChange={(selectedOption) => {
                  const selectedTime = timeOptions.find((to) => to.id === String(dispatchTime));
                  const newTimeOptions = getTimeDropdownOptions(
                    finalAvailability,
                    restaurant.timezone,
                    Number(selectedOption.id),
                    restaurant.orders.future.delayMins,
                  );
                  const newSelectedDateTime =
                    selectedTime &&
                    Number(newTimeOptions.find((to) => to.value === selectedTime.value)?.id || newTimeOptions[0].id);

                  onChange({
                    timingOption,
                    selectedDateTime: newSelectedDateTime || Number(selectedOption.id),
                  });
                }}
                initialSelectedId={String(selectedDate)}
                error={Boolean(dateError)}
                errorMessage={dateError}
                mobileNativeSelect
              />
            </div>
            <div className={`${styles.spacer} ${isMobile && styles.mobile}`} />
            <div id="time-selector" className={styles.time}>
              <Dropdown
                upgrade
                data-hook={dataHooks.dispatchSummaryTimingTime}
                options={timeOptions}
                onChange={(selectedOption) => {
                  onChange({ timingOption, selectedDateTime: Number(selectedOption.id) });
                }}
                initialSelectedId={String(dispatchTime)}
                error={Boolean(timeError)}
                errorMessage={timeError}
                mobileNativeSelect
              />
            </div>
          </div>
          <div className={styles.commentWrapper}>
            <Text typography="p2-xs">Date and time are in "{restaurant.timezone}" timezone.</Text>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default React.memo(DispatchTime);
