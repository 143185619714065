function getTopElement(offsetX: number): HTMLElement | null {
  try {
    const topElements = document.elementsFromPoint(offsetX, 0);
    return topElements.find((element) => !element.className.includes('modal-overlay')) as HTMLElement;
  } catch (e) {
    return null;
  }
}

function isPositionFixed(element?: Element | null): boolean {
  if (!element) {
    return false;
  }

  try {
    const { position, opacity } = window.getComputedStyle(element);
    return position === 'fixed' && opacity === '1';
  } catch (e) {
    return false;
  }
}

export function calculateTopValue(offsetX: number) {
  try {
    const topHeader = document.querySelector('#SITE_HEADER') as HTMLHeadElement;

    if (topHeader && (isPositionFixed(topHeader) || isPositionFixed(topHeader?.parentElement))) {
      return topHeader.offsetHeight + topHeader.offsetTop;
    } else {
      const topElement = getTopElement(offsetX);

      if (topElement && (isPositionFixed(topElement) || isPositionFixed(topElement?.parentElement))) {
        return topElement.offsetHeight + topElement.offsetTop;
      }
    }
  } catch (e) {}

  return 0;
}
