import moment from 'moment-timezone';
import { createSelector } from 'reselect';
import { AppState } from '../createStore';
import StatusHelper from '@wix/wixrest-utils/dist/openrest-helpers/Status';
import _ from 'lodash';
import { Address, BusinessNotifications, Restaurant, isSupportsFutureOrder } from '@wix/restaurants-client-logic';
import { DEFAULT_ADDRESS, NO_ADDRESS, DEFAULT_ADDRESS_SHORT } from '../../core/constants';

export interface BusinessNotificationDetails {
  notification: BusinessNotifications;
  comment?: string;
}

export function getAllBusinessNotifications(
  restaurant: Restaurant,
  hasAnyPaymentMethods: boolean,
  dispatchTime?: number,
): BusinessNotificationDetails[] {
  const calendar = moment(dispatchTime).tz(restaurant.timezone);
  const restStatus = StatusHelper.getStatus(restaurant.openTimes, calendar);
  const notifications = [];

  if (!hasAnyPaymentMethods) {
    notifications.push({ notification: { notification: BusinessNotifications.NoPaymentMethods }, weight: 1100 });
  }

  if (!isPremiumRestaurant(restaurant)) {
    notifications.push({ notification: { notification: BusinessNotifications.NotPremium }, weight: 1000 });
  }

  if (isNoPhysicalLocation(restaurant.address)) {
    notifications.push({ notification: { notification: BusinessNotifications.NoPhysicalLocation }, weight: 900 });
  }

  if (restStatus.status === 'unavailable') {
    const closedNotifications = isSupportsFutureOrder(restaurant)
      ? BusinessNotifications.OnlyFutureOrders
      : BusinessNotifications.RestaurantClosed;
    notifications.push({ notification: { notification: closedNotifications }, weight: 800 });
  }

  const oloStatus = StatusHelper.getStatus(_.get(restaurant, 'orders.availability'), calendar);

  if (oloStatus.status === 'unavailable') {
    const { locale } = restaurant;
    const comment = _.get(oloStatus, `comment[${locale}]`);
    notifications.push({ notification: { notification: BusinessNotifications.OlOunavailable, comment }, weight: 600 });
  }

  notifications.sort((a, b) => b.weight - a.weight);
  return notifications.map((nd) => nd.notification);
}

export function businessNotificationSelectorInner(
  restaurant: Restaurant,
  hasAnyPaymentMethods: boolean,
  dispatchTime?: number,
): BusinessNotificationDetails | undefined {
  const notifications = getAllBusinessNotifications(restaurant, hasAnyPaymentMethods, dispatchTime);
  return notifications.length > 0 ? notifications[0] : undefined;
}

export const isPremiumRestaurant = (restaurant: Restaurant) =>
  _.some(restaurant.products, {
    id: 'com.wix.restaurants.orders',
    param: 'premium',
  });

export const isNoPhysicalLocation = (address: Address) => {
  return (
    address &&
    (address.formatted === NO_ADDRESS ||
      address.formatted === DEFAULT_ADDRESS ||
      address.formatted === DEFAULT_ADDRESS_SHORT)
  );
};

export const businessNotificationSelector = createSelector(
  (state: AppState) => state.session.restaurant,
  (state: AppState) => state.session.paymentMethods.length > 0,
  businessNotificationSelectorInner,
);

export const businessNotificationSelectorByDispatchTime = createSelector(
  [
    (state: AppState) => state.session.restaurant,
    (state: AppState) => state.session.paymentMethods.length > 0,
    (state: AppState) => state.checkout.dispatch.time,
  ],
  businessNotificationSelectorInner,
);

export const allBusinessNotificationsSelectorByDispatchTime = createSelector(
  [
    (state: AppState) => state.session.restaurant,
    (state: AppState) => state.session.paymentMethods.length > 0,
    (state: AppState) => state.checkout.dispatch.time,
  ],
  getAllBusinessNotifications,
);
