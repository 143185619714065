import { DisplayableChoice, DisplayableOrderItem, DisplayableVariation } from '@wix/restaurants-client-logic';

export const _hasInvalidVariations = (displayableOrderItem: DisplayableOrderItem | DisplayableChoice): boolean => {
  return displayableOrderItem.variations.some((v) => _isInvalidVariation(v));
};

export const _isInvalidVariation = (variation: DisplayableVariation): boolean => {
  return !variation.isValid || variation.choices.some((dc) => _hasInvalidVariations(dc));
};

export const _initializeErrorVisibilityObject = (variations: DisplayableVariation[]): Record<string, boolean> => {
  return variations.reduce<Record<string, boolean>>((res, v) => {
    if (_isInvalidVariation(v)) {
      res[v.clientSideId] = true;
    }

    return { ...res, ..._initializeErrorVisibilityObject(v.choices.flatMap((dc) => dc.variations)) };
  }, {});
};

export const getInvalidVariations = (
  displayableOrderItem: DisplayableOrderItem | DisplayableChoice,
): DisplayableVariation[] => {
  const invalidVariations: DisplayableVariation[] = [];
  displayableOrderItem.variations.forEach((v: DisplayableVariation) => {
    if (_isInvalidVariation(v)) {
      invalidVariations.push(v);
    }
  });
  return invalidVariations;
};
