import { TranslationFunction } from 'i18next';
import {
  DispatchType,
  DispatchInfo,
  Availability,
  IntegerInterval,
  getDateOptions,
  getAsapParams,
  getDaysDiff,
  getTimeOptions,
} from '@wix/restaurants-client-logic';
import Time from '@wix/wixrest-utils/dist/Time';
import { DropdownOptionProps } from 'wix-ui-tpa/dist/src/components/Dropdown/DropdownOption';

interface getAsapTextArgs {
  deliveryInfos: DispatchInfo[];
  timezone: string;
  dispatchType: DispatchType;
  t: TranslationFunction;
  idealDeliveryArea?: DispatchInfo;
}

export function getAsapText({ idealDeliveryArea, deliveryInfos, dispatchType, t }: getAsapTextArgs): string {
  const asapParams = getAsapParams({ idealDeliveryArea, deliveryInfos, dispatchType });
  let text;
  switch (asapParams.type) {
    case 'unavailable':
    case 'invalid':
    case 'immediate':
      text = '';
      break;
    case 'range':
      const { min, max } = asapParams;
      const minString =
        Time.getTimeUnitFromMinutes(min).unit === Time.getTimeUnitFromMinutes(max).unit
          ? Time.getTimeUnitFromMinutes(min).value
          : Time.humanizeMinutesDuration(min);
      const maxString = Time.humanizeMinutesDuration(max);
      text = t('main_page_minmaxminutes', { min: minString, max: maxString });
      break;
    case 'exact':
      const key = dispatchType === 'delivery' ? 'main_page_maxminutes' : 'main_page_inminutes';
      text = t(key, { delayMin: Time.humanizeMinutesDuration(asapParams.value) });
      break;
    default:
      break;
  }
  return `${t('main_page_asap')} ${text}`;
}

export const formatDate = (timestamp: number, timezone: string, fallback: string, t: TranslationFunction): string => {
  const diff = getDaysDiff(timestamp, timezone);

  if (diff === 0) {
    return t('order_settings_modal_dropdown_today');
  } else if (diff === 1) {
    return t('order_settings_modal_dropdown_tomorrow');
  } else {
    return fallback;
  }
};

export const getDateDropdownOptions = (
  availability: Availability,
  timezone: string,
  numberOfDays: number,
  delayMins: IntegerInterval,
  t: TranslationFunction,
): DropdownOptionProps[] => {
  return getDateOptions({ availability, timezone, numberOfDays, delayMins }).map((o) => ({
    id: String(o.timestamp),
    value: formatDate(o.timestamp, timezone, o.displayableValue, t),
    isSelectable: true,
  }));
};

export const getTimeDropdownOptions = (
  availability: Availability,
  timezone: string,
  selectedDate: number,
  delayMins: IntegerInterval,
): DropdownOptionProps[] => {
  return getTimeOptions({ availability, day: selectedDate, timezone, delayMins }).map((o) => ({
    id: String(o.timestamp),
    value: o.displayableValue,
    isSelectable: true,
  }));
};
